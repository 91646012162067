import { isDomain } from "../model/Domain";
import { isArrayOf } from "../model/IdentityHelpers";
import { api_url, jsonRequest, routeconf } from "./Const";
import { hasAdminPassword, hasDomain, hasGroupId } from "./DTO";

async function newDomain(request: unknown) {
    if (!hasDomain(request)) {
        throw new TypeError(`${JSON.stringify(request)} has no Domain`)
    }

    if (!hasAdminPassword(request)) {
        throw new TypeError(`${JSON.stringify(request)} hs no admin password`)
    }

    await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Domains.Base}${routeconf.Domains.Create}`, request, true)
}

async function deleteDomain(request: unknown) {
    if (!hasDomain(request)) {
        throw new TypeError(`${JSON.stringify(request)} has no Domain`)
    }

    if (!hasAdminPassword(request)) {
        throw new TypeError(`${JSON.stringify(request)} hs no admin password`)
    }

    await jsonRequest("DELETE", `${api_url}${routeconf.Base}${routeconf.Domains.Base}/${request.domain}`, request, true)
}

async function domainAddGroup(request: unknown) {
    if (!hasDomain(request)) {
        throw new TypeError(`${JSON.stringify(request)} has no Domain`)
    }

    if (!hasGroupId(request)) {
        throw new TypeError(`${JSON.stringify(request)} has no groupId`)
    }

    if (!hasAdminPassword(request)) {
        throw new TypeError(`${JSON.stringify(request)} hs no admin password`)
    }

    await jsonRequest("PUT", `${api_url}${routeconf.Base}${routeconf.Domains.Base}${routeconf.Domains.AddGroup}`, request, true)
}

async function domainRemoveGroup(request: unknown) {
    if (!hasDomain(request)) {
        throw new TypeError(`${JSON.stringify(request)} has no Domain`)
    }

    if (!hasGroupId(request)) {
        throw new TypeError(`${JSON.stringify(request)} has no groupId`)
    }

    if (!hasAdminPassword(request)) {
        throw new TypeError(`${JSON.stringify(request)} hs no admin password`)
    }

    await jsonRequest("DELETE", `${api_url}${routeconf.Base}${routeconf.Domains.Base}${routeconf.Domains.RemGroup}`, request, true)
}

async function getDomains(request: unknown){
    if (!hasAdminPassword(request)) {
        throw new TypeError(`${JSON.stringify(request)} hs no admin password`)
    }

    const domains = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Domains.Base}${routeconf.Domains.Get}`, request)

    if(!isArrayOf(domains, isDomain)){
        throw new TypeError(`${JSON.stringify(domains)} is not Domain[]`)
    }

    return domains
}

export { newDomain, deleteDomain, domainAddGroup, domainRemoveGroup, getDomains }