import { isClientApp } from "../model/ClientApp"
import { isArrayOf } from "../model/IdentityHelpers"
import { api_url, jsonRequest, routeconf } from "./Const"
import { hasAdminPassword, isAppGroupMapping, isNewClientAppRequest, hasAppId } from "./DTO"

async function addGroupToAppLimit(request: unknown){

    if(!isAppGroupMapping(request)){
        throw new TypeError(`${JSON.stringify(request)} is no AppGroupMapping`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("PUT", `${api_url}${routeconf.Base}${routeconf.ClientApp.Base}/group/${request.appId}`, request)

    if(!isClientApp(result)){
        throw new TypeError(`${JSON.stringify(result)} is not ClientApp`)
    }

    return result

}

async function removeGroupFromAppLimit(request: unknown){

    if(!isAppGroupMapping(request)){
        throw new TypeError(`${JSON.stringify(request)} is no AppGroupMapping`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("DELETE", `${api_url}${routeconf.Base}${routeconf.ClientApp.Base}/group/${request.appId}`, request)

    if(!isClientApp(result)){
        throw new TypeError(`${JSON.stringify(result)} is not ClientApp`)
    }

    return result;
}

async function newClientapp(request: unknown){
    
    if(!isNewClientAppRequest(request)){
        throw new TypeError(`${JSON.stringify(request)} is not NewClientAppRequest`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.ClientApp.Base}${routeconf.ClientApp.create}`, request)

    if(!isClientApp(result)){
        throw new TypeError(`${JSON.stringify(request)} is not ClientApp`)
    }

    return result;

}

async function getClientApps(request: unknown){
    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.ClientApp.Base}${routeconf.ClientApp.Get}`, request)

    if(!isArrayOf(result, isClientApp)){
        throw new TypeError(`${JSON.stringify(result)} is not ClientApp[]`)
    }

    return result;
}

async function toggleClientAppGroupLimitPropagation(request: unknown){
    if(!hasAppId(request)){
        throw new TypeError(`${JSON.stringify(request)} has no appId`)
    }
    
    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("PATCH", `${api_url}${routeconf.Base}${routeconf.ClientApp.Base}/group/${request.appId}`, request)

    if(!isClientApp(result)){
        throw new TypeError(`${JSON.stringify(result)} is not ClientApp`)
    }

    return result;
}

async function refreshClientAppSecret(request: unknown){
    if(!hasAppId(request)){
        throw new TypeError(`${JSON.stringify(request)} has no appId`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.ClientApp.Base}/secret/${request.appId}`, request);

    if(!isClientApp(result)){
        throw new TypeError(`${JSON.stringify(result)} is not ClientApp`)
    }

    return result
}

export { addGroupToAppLimit, getClientApps, newClientapp, removeGroupFromAppLimit, toggleClientAppGroupLimitPropagation, refreshClientAppSecret }
