import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Domain } from "../../model/Domain";

interface DomainSliceState{
    all?: Domain[]
}

const initialState: DomainSliceState = {};

const domainSlice = createSlice({
    name: "domains",
    initialState,
    reducers: {
        setAll:(state, action: PayloadAction<Domain[]>)=>{
            state.all = action.payload;
        },
        clear: (state)=>{
            state.all = undefined;
        }
    }
})

export const {setAll, clear} = domainSlice.actions;

export default domainSlice.reducer;