import { useState } from "react"
import DisplayField from "../../components/DisplayField"
import ProfilePicture from "../../components/ProfilePicture"
import { RemoteUser } from "../../model/RemoteUser"
import * as UsersApi from "../../api/User";
import { useAppDispatch } from "../../redux/store";
import { setAction } from "../../redux/reducers/adminAction";
import Form from "../../components/Form";
import { login } from "../../api/Auth";
import * as userDispatch from "../../redux/reducers/userSlice"
import * as domainDispatch from "../../redux/reducers/domainSlice";
import * as clientAppDispatch from "../../redux/reducers/clientAppSlice";
import * as settingsDispatch from "../../redux/reducers/settingsSlice";
import * as adminActionDispatch from "../../redux/reducers/adminAction";
import * as groupActionDispatch from "../../redux/reducers/groupSlice";
import { isAny } from "../../model/IdentityHelpers";
import { useNavigate } from "react-router";
import ShimmerButton from "../../components/ShimmerButton";
import ButtonHeightFixer from "../../components/ButtonHeightFixer";

interface RemoteUserViewProps {
    user: RemoteUser,
    editable?: boolean,
    canAddchild?: boolean,
    mayAssingAdmin?: boolean
}

function RemoteUserView(props: RemoteUserViewProps) {

    const [profilePicture, setProfilePicture] = useState<{ dataUrl: string } | null>(null)

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    return <div className="widget">
        <ProfilePicture picture={profilePicture?.dataUrl || props.user.picture} canEdit={props.editable ?? false} fromCamera={(dataUrl)=>{
            setProfilePicture({dataUrl})
        }} onFileSelect={(file) => {
            if (file.size >= 512 * 1024) {
                throw new Error("File too large, max size 512 kb")
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicture({ dataUrl: reader.result as string })
            }
            reader.readAsDataURL(file)
        }}  setProfilePicture={(_) => {
            if (profilePicture)
                UsersApi.setProfilePicture(profilePicture.dataUrl)
        }} />
        <div className="remoteViewFields">
            <div>
                <DisplayField label="Name" field={props.user.name ?? ""} />
                <DisplayField label="Display Name" field={props.user.displayName ?? ""} />
                <DisplayField label="Email" field={props.user.username} />
                <DisplayField label="Admin" field={props.user.adminId ? "Yes" : "No"} />
            </div>
            {props.editable && <div className="remoteUserView_EditButtonContainer"><ButtonHeightFixer /><ShimmerButton onClick={() => {
                navigate("/NewUserDetails")
            }}>Edit</ShimmerButton></div>}
            <h4>Groups</h4>
            {props.user.groups !== undefined && props.user.groups.length > 0 ? <ul>
                {props.user.groups.map((g) => <li key={g.id}>{g.name}</li>)}
            </ul> : "No Groups"}
            {props.user.parent == null && props.user.adminId == null && props.mayAssingAdmin && <button onClick={() => {
                dispatch(setAction({ action: "assignAdmin", data: props.user.id }))
            }} >Promote to Admin</button>}
            {props.canAddchild && <Form wrapperClass="remoteViewFields_form_wrapper" headerWrapperClass="remoteViewFields_form_header" headerContent={<h4>Add Child</h4>} bodyElements={[{
            label: "Email", name: "email"
        }, {
            label: "Password", name: "password", type: "password"
        }]} submitAction={async (data) => {
            if (isAny(data)) {
                const addChildResponse = await UsersApi.addChild(data);
                if (addChildResponse.isNew) {
                    dispatch(userDispatch.clear())
                    dispatch(adminActionDispatch.clear())
                    dispatch(clientAppDispatch.clear())
                    dispatch(domainDispatch.clear())
                    dispatch(groupActionDispatch.clear())
                    dispatch(settingsDispatch.clear())
                    dispatch(userDispatch.setCurrent({
                        id: addChildResponse.user.id,
                        username: data.email,
                        picture: null,
                        parent: null,
                        adminId: null
                    }))
                    await login(data)
                    navigate("/NewUserDetails")
                } else {
                    dispatch(userDispatch.setCurrent({ ...props.user, children: [...props.user.children || [], addChildResponse.user] }))
                }
            }
        }} cancelAction={() => { }} />}
        </div>



        

        
    </div>
}

export default RemoteUserView