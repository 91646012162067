import { useEffect } from "react"
import { useNavigate } from "react-router"
import { checkNeedsAdmin } from "../api/Auth"
import { getSelf } from "../api/User"
import { useAppDispatch, useAppSelector } from "../redux/store"
import { setCurrent, setTree } from "../redux/reducers/userSlice"
import RemoteUserView from "./Dashboard/RemoteUserView"
import { setAction } from "../redux/reducers/adminAction"
import GroupView from "./Dashboard/GroupView"
import { getGroups } from "../api/Groups"
import { setAll } from "../redux/reducers/groupSlice"
import Form from "../components/Form"
import ClientappView from "./Dashboard/ClientAppView"
import DomainView from "./Dashboard/DomainView"

const Dashboard = () => {

    const { current, all } = useAppSelector((state) => state.userSlice)

    const groups = useAppSelector((state) => state.groupSlice.all)

    const apps = useAppSelector((state) => state.clientAppSlice.all)

    const domains = useAppSelector((state)=> state.domainSlice.all)

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        if (!current)
            getSelf().then((self) => {
                dispatch(setCurrent(self.self));
                dispatch(setTree(self.tree));
            }, () => {
                checkNeedsAdmin().then((needsAdmin) => {
                    if (needsAdmin) {
                        navigate("/CreateAdmin")
                    } else {
                        navigate("/Login")
                    }
                })
            })

    }, [current, dispatch, navigate])


    return <div className="module">
        {current !== undefined && <RemoteUserView user={current} canAddchild editable />}
        {current?.adminId !== null && <div><button onClick={() => {
            dispatch(setAction({ action: "getUsers" }))
        }}>Get Users</button>
            {all !== undefined && all.filter((u) => u.id !== current?.id).map((user) => <RemoteUserView user={user} mayAssingAdmin key={user.id} />)}
        </div>}
        <button onClick={async () => {
            const groups = await getGroups();
            dispatch(setAll(groups))
        }}>Get Groups</button>
        {groups !== undefined && groups.map((g) => <GroupView group={g} adminActions={current?.adminId !== null} />)}
        {current?.adminId !== null && <Form headerContent={"New Group"} bodyElements={[{
            label: "Group Name", name: "name"
        }]} submitAction={(group) => {
            dispatch(setAction({ action: "newGroup", data: { group } }))
        }} cancelAction={() => {

        }} />}
        {current?.adminId !== null && <div>
            <button onClick={() => {
                dispatch(setAction({ action: "getClientApps" }))
            }} >Get Client Apps</button>
            {apps !== undefined && apps.map((app) => <ClientappView app={app} key={app.id} />)}
            <Form headerContent={"Register New Client Application"} bodyElements={[{
                name: "name", label: "Application Name"
            }, {
                name: "redirectUrl", label: "Redirect URL"
            }]} submitAction={(data) => {
                dispatch(setAction({ action: "newClientApp", data }))
            }} cancelAction={() => { }} />
        </div>}
        {current?.adminId!==null&&<div>
            <button onClick={()=>{
                dispatch(setAction({action:"getDomains"}))
            }}>Get Domains</button>
            {domains!==undefined&& domains.map((domain)=><DomainView domain={domain} />)}
            <Form headerContent={"New Domain"} bodyElements={[{
                name:"domain", label:"Domain"
            }]} submitAction={(data)=>{
                dispatch(setAction({action:"newDomain", data}))
            }} cancelAction={function () {
                
            } } />
            </div>}
    </div>
}

export default Dashboard;