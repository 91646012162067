import { isAny, isArrayOf } from "../model/IdentityHelpers";
import { RemoteUser, isRemoteUser } from "../model/RemoteUser";
import { api_url, jsonRequest, routeconf } from "./Const";
import { hasAdminPassword, isAddChildResponse, isAuthRequest } from "./DTO";

async function getSelf(){
    
    const request = await fetch(`${api_url}${routeconf.Base}${routeconf.Users.Base}`)

    if(!request.ok){
        throw new Error(`${request.status}: ${request.statusText}`)
    }

    const reflection = await request.json();

    if(!isRemoteUser(reflection.self)||!isRemoteUser(reflection.tree)){
        throw new TypeError(`${JSON.stringify(reflection.self)} is not RemoteUser`)
    }

    return reflection as {self: RemoteUser, tree: RemoteUser};
}

async function setUserDetails(data: unknown){
    if(!isRemoteUser(data)){
        throw new TypeError(`${JSON.stringify(data)} is not RemoteUser`)
    }

    await jsonRequest("PUT", `${api_url}${routeconf.Base}${routeconf.Users.Base}${routeconf.Users.Update}`, {user: data}, true)

}

async function setProfilePicture(dataUrl: string){
    
    const info: any = {}
    
    try{
        const [meta, data] = dataUrl.split(",");
        const [fifo,encoding] = meta.split(";")
        const [scheme, mime] = fifo.split(":")

        info.scheme = scheme;
        info.mime = mime;
        info.encoding = encoding;
        info.data = data;
    }catch(e){
        throw new Error("Error parsing Data: Not a Data URL")
    }

    const result = await fetch(`${api_url}${routeconf.Base}${routeconf.Users.Base}${routeconf.Users.picture}`, 
        {
            method:"PUT", 
            headers:{
                "Content-Type": info.mime
            }, 
            body: Uint8Array.from(
                atob(info.data), 
                (c)=>c.charCodeAt(0)
                ).buffer
    })

    if(!result.ok){
        throw new Error(`${result.status}: ${result.statusText}`)
    }
    
}

async function getAllUsers(request: unknown){
    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    const result = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Users.Base}${routeconf.Users.Get}`, request)

    if(!isAny(result.users)||!isArrayOf(result.users, isRemoteUser)){
        throw new TypeError(`${JSON.stringify(result)} has no users`)
    }

    return result.users as RemoteUser[]

}

async function addChild(request: unknown){
    if(!isAuthRequest(request)){
        throw new TypeError(`${JSON.stringify(request)} is not AuthRequest`)
    }

    const result = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Users.Base}${routeconf.Users.AddChild}`, request)

    if(!isAddChildResponse(result)){
        throw new TypeError(`${JSON.stringify(result)} is not AddChildResponse`)
    }
    
    return result;
}

export { addChild, getAllUsers, getSelf, setProfilePicture, setUserDetails };
