import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userSlice from "./reducers/userSlice";
import domainSlice from "./reducers/domainSlice";
import groupSlice from "./reducers/groupSlice";
import settingsSlice from "./reducers/settingsSlice";
import clientAppSlice from "./reducers/clientAppSlice";
import adminAction from "./reducers/adminAction";

const store = configureStore({
    reducer: {
        userSlice,
        domainSlice,
        groupSlice,
        clientAppSlice,
        settingsSlice,
        adminAction,   
    }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: ()=> AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;