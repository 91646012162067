import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientApp } from "../../model/ClientApp";

interface ClientAppSliceState{
    all?: ClientApp[]
}

const initialState : ClientAppSliceState = {};

const clientAppSlice = createSlice({
    name: "clientApps",
    initialState,
    reducers: {
        setAll: (state, action: PayloadAction<ClientApp[]>)=>{
            state.all = action.payload;
        },
        clear: (state)=>{
            state.all = undefined;
        }
    }
})

export const {setAll, clear} = clientAppSlice.actions;

export default clientAppSlice.reducer;