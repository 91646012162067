import { GroupAll, isGroup, isGroupAll } from "../model/Group";
import { isArrayOf } from "../model/IdentityHelpers";
import { api_url, jsonRequest, routeconf } from "./Const"
import { hasAdminPassword, isNewGroupRequest, isUserGroupMapping } from "./DTO";

async function getGroups(){

    const request = await fetch(`${api_url}${routeconf.Base}${routeconf.Groups.Base}${routeconf.Groups.Get}`)

    if(!request.ok){
        throw new Error(`${request.status}: ${request.statusText}`)
    }

    const result = await request.json();
    
    if(!isArrayOf(result, (o): o is GroupAll=>isGroup(o)&&isGroupAll(o))){
        throw new TypeError(`${JSON.stringify(request)} is not GroupAll[]`)
    }
    
    return result

}

async function addUserToGroup(request: unknown){
    
    if(!isUserGroupMapping(request)){
        throw new TypeError(`${JSON.stringify(request)} is not UserGroupMapping`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    await jsonRequest("PUT", `${api_url}${routeconf.Base}${routeconf.Groups.Base}${routeconf.Groups.Put}`, request, true)

}

async function removeUserFromGroup(request: unknown){
    if(!isUserGroupMapping(request)){
        throw new TypeError(`${JSON.stringify(request)} is not UserGroupMapping`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }

    await jsonRequest("DELETE", `${api_url}${routeconf.Base}${routeconf.Groups.Base}${routeconf.Groups.Delete}`, request, true)
}

async function createGroup(request: unknown){
    if(!isNewGroupRequest(request)){
        throw new TypeError(`${JSON.stringify(request)} is not NewGroupRequest`)
    }

    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify} has no admin password`)
    }

    const result = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Groups.Base}${routeconf.Groups.Create}`, request)

    if(!isGroup(result)){
        throw new TypeError(`${JSON.stringify(result)} is not Group`)
    }
}

export {getGroups, addUserToGroup, removeUserFromGroup, createGroup}