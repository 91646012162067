import { ReactNode } from "react"

interface DisplayFieldProps {
    wrapperClass?: string
    labelClass?: string
    label: string
    fieldClass?: string
    field: ReactNode
}

function DisplayField(props: DisplayFieldProps) {

    return <div className={`displayfield_wrapper ${props.wrapperClass ?? ""}`} >
        <label className={`displayfield_label ${props.labelClass ?? ""}`} >{props.label}</label>
        <label className={`displayfield_field ${props.fieldClass ?? ""}`} >{props.field}</label>
    </div>
}

export default DisplayField