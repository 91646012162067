import { isAny, isString, isUndefined } from "../model/IdentityHelpers"
import { RemoteUser, isRemoteUser } from "../model/RemoteUser";

export interface AuthRequest {
    email: string
    password: string
}

export function isAuthRequest(obj: unknown): obj is AuthRequest {
    if (!isAny(obj)) {
        return false;
    }
    if (!isString(obj.email) || !isString(obj.password)) {
        return false;
    }
    return true;
}

export interface CreateAdminRequest extends AuthRequest {
    adminPassword: string
}

export function isCreateAdminRequest(obj: unknown): obj is CreateAdminRequest {
    if (!isAuthRequest(obj)) {
        return false;
    }
    if (!isString((obj as any).adminPassword)) {
        return false;
    }
    return true;
}

export interface WithAdminPassword {
    password: string
}

export function hasAdminPassword(obj: unknown): obj is WithAdminPassword {
    if (!isAny(obj)) {
        return false;
    }
    if (!isString(obj.password)) {
        return false;
    }
    return true;
}

export interface AddChildResponse {
    isNew: boolean,
    user: RemoteUser
    admin: null
}

export function isAddChildResponse(obj: unknown): obj is AddChildResponse {
    if (!isAny(obj)) {
        return false;
    }
    if (!isRemoteUser(obj.user)) {
        return false;
    }
    if (!isUndefined(obj.admin)) {
        return false;
    }

    return true;
}

export interface UserGroupMapping {
    userId: string,
    groupId: string
}

export function isUserGroupMapping(obj: unknown): obj is UserGroupMapping {

    if (!isAny(obj)) {
        return false;
    }
    if (!isString(obj.groupId) || !isString(obj.userId)) {
        return false;
    }

    return true;
}

export interface NewGroupRequest {
    group: {
        name: string
    }
}

export function isNewGroupRequest(obj: unknown): obj is NewGroupRequest {
    if (!isAny(obj)) {
        return false;
    }

    if (!isAny(obj.group)) {
        return false;
    }

    if (!isString(obj.group.name)) {
        return false;
    }

    return true;

}

export interface AppGroupMapping {
    appId: string,
    groupId: string
}

export function isAppGroupMapping(obj: unknown): obj is AppGroupMapping {

    if (!isAny(obj)) {
        return false;
    }

    if (!isString(obj.appId) || !isString(obj.groupId)) {
        return false;
    }


    return true
}

export interface NewClientAppRequest {
    name: string
    redirectUrl: string
}

export function isNewClientAppRequest(obj: unknown): obj is NewClientAppRequest {

    if (!isAny(obj)) {
        return false;
    }

    if (!isString(obj.name) || !isString(obj.redirectUrl)) {
        return false;
    }

    return true;
}

export interface WithAppId{
    appId: string
}

export function hasAppId(obj: unknown): obj is WithAppId{

    if(!isAny(obj)||!isString(obj.appId)){
        return false;
    }

    return true
}

export interface WithDomain{
    domain: string
}

export function hasDomain(obj: unknown): obj is WithDomain{

    if(!isAny(obj)){
        return false;
    }

    if(!isString(obj.domain)){
        return false;
    }

    return true
}

export interface WithGroupId{
    groupId: string
}

export function hasGroupId(obj: unknown): obj is WithGroupId{

    if(!isAny(obj)){
        return false;
    }

    if(!isString(obj.groupId)){
        return false;
    }

    return true;
}