import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RemoteUser } from "../../model/RemoteUser";

interface UserSliceState{
    current?: RemoteUser,
    tree?:RemoteUser,
    all?:RemoteUser[]
}

const initialState: UserSliceState = {};

const UserSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setCurrent:(state, action: PayloadAction<RemoteUser>)=>{
            state.current = action.payload;
        },
        setTree:(state, action: PayloadAction<RemoteUser>)=>{
            state.tree = action.payload
        },
        setAll:(state, action: PayloadAction<RemoteUser[]>)=>{
            state.all = action.payload;
        },
        clear:(state)=>{
            state.all = undefined;
            state.current = undefined;
            state.tree = undefined;
        }
    }
})

export const {setCurrent, setAll,setTree, clear} = UserSlice.actions;

export default UserSlice.reducer;