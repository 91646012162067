const api_url =  process.env.NODE_ENV==="development"?"http://localhost:3000":"https://imap-oidc.thern.wtf" 

const routeconf = {
    Base: "/api",
    Auth: {
        Base: "/auth",
        Login: "/login",
        CreateAdmin:"/createadmin",
        Logout: "/logout",
        AssignAdmin:"/:userId",
        setAdminPassword:"/setpass",
        openId:{
            Base: "/openid",
            auth: "/auth",
            token: "/token",
            user: "/user",
            jwks: "/jwks",
            end:"/logout",
        },
    },
    Users: {
        Base: "/users",
        Get: "/all",
        Add: "/add",
        Update: "/update",
        AddChild:"/addchild",
        picture:"/picture",
    },
    Groups: {
        Base: "/groups",
        Get:"/all",
        Create: "/create",
        Put:"/adduser",
        Delete:"/remuser",
    },
    Domains:{
        Base: "/domains",
        Delete: "/:domain",
        Get: "/all",
        Create:"/create",
        Put:"/edit",
        AddGroup:"/addgroup",
        RemGroup:"/remgroup",
    },
    Settings:{
        Base:"/settings",
        Get:"/all",
        SetUnset:"/:key",
    },
    ClientApp: {
        Base:"/clientapp",
        Get: "/all",
        create:"/",
        secret:"/secret/:clientAppId",
        group:"/group/:clientAppId",
        redirect:"/redirect/:clientAppId",
        delete:"/:clientAppId",
    },
}

type RequestMethod = "POST"|"PUT"|"PATCH"|"DELETE"

async function jsonRequest(method: RequestMethod, url: string, body: object, emptyResponse = false){
    const request = await fetch(url, {method, body: JSON.stringify(body), headers:{"Content-Type":"application/json"}});
    if(!request.ok){
        throw new Error(`${request.status}: ${request.statusText}`)
    }
    if(!emptyResponse)
    return await request.json()
}
export {
    api_url,
    routeconf,
    jsonRequest
};