interface ButtonHeightFixerProps{
    lightBackground?:string,
    darkBackground?:string
}

function ButtonHeightFixer(props: ButtonHeightFixerProps){
    return <button style={{background:"transparent"}}>
        <span style={{color:"transparent"}}>f</span>
    </button>
}

export default ButtonHeightFixer