import React, { useEffect, useRef, useState } from "react"
import { isAny } from "../model/IdentityHelpers"
import ShimmerButton from "./ShimmerButton"

interface ProfilePicturePropsEditable {
    picture: string | null
    canEdit: true
    onFileSelect: (file: File) => void
    setProfilePicture: (ev: any) => void
    fromCamera:(dataUrl: string)=>void
}

interface ProfilePicturePropsNotEditable {
    picture: string | null
    canEdit: false
}

interface ProfilePictureClasses {
    wrapperClass?: string,
    imageClass?: string,
    linkClass?: string,
    editWrapperClass?: string
    selectButtonClass?: string,
    sendButtonClass?: string
}

type ProfilePictureProps = ProfilePicturePropsEditable | ProfilePicturePropsNotEditable

function ProfilePicture(props: ProfilePictureProps & ProfilePictureClasses) {

    const [cameraState, setCameraState] = useState<"idle"|"activating"|"ready"|undefined>(undefined)
    const [videoDimensions, setVideoDimensions] = useState<{width:number, height: number}|undefined>(undefined)

    const vidRef = useRef(null)
    useEffect(()=>{
        navigator.mediaDevices.enumerateDevices().then((devices)=>{
            if(cameraState===undefined&&devices.find((device)=>device.kind==="videoinput")){
                setCameraState("idle")
            }else if(cameraState==="activating"){
                if(vidRef!=null&&vidRef.current!=null){
                    navigator.mediaDevices.getUserMedia({video:{facingMode:{ideal:"user"}}}).then((stream)=>{
                        (vidRef.current as any).srcObject = stream;
                        setCameraState("ready")
                    })
                }
            }else if(cameraState==="ready"){
                if(vidRef!=null&&vidRef.current!=null){
                    (vidRef.current as HTMLVideoElement).play().then(()=>{
                        setVideoDimensions({height: ((vidRef as any).current as HTMLVideoElement).videoHeight, width: ((vidRef as any).current as HTMLVideoElement).videoWidth})
                    });
                }
            }else if(cameraState==="idle"){
                if(vidRef!=null&&vidRef.current!=null){
                    
                }
            }
        })
    }, [cameraState, vidRef])

    const inputRef = useRef(null)

    console.log(videoDimensions)

    return <div className={`profile_wrapper ${props.wrapperClass ?? ""}`}>

        {(!cameraState||cameraState==="idle")&&<img src={props.picture ?? "/phantom.png"} className={`profile_img${props.canEdit&&cameraState==="idle"?"_clickable":""} ${props.imageClass ?? ""}`} 
        style={window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && props.picture ? {} : { filter: "invert(1)" }} 
        alt="" onClick={()=>{
                setCameraState("activating");
        }} />}
        {props.canEdit&&<video className="profile_img_clickable" ref={vidRef} style={(cameraState==="ready"||cameraState==="activating")?{aspectRatio:1}:{display:"none"}} onClick={(ev)=>{
            
            if(cameraState==="ready"){
                const {videoHeight, videoWidth} = (ev.target as HTMLVideoElement)
                const shortSide = videoHeight<videoWidth?videoHeight:videoWidth;
                const longSide = videoWidth>videoHeight?videoWidth:videoHeight;
                const canvas = document.createElement("canvas");
                canvas.width=shortSide>500?500:shortSide;
                canvas.height=canvas.width;
                canvas.getContext("2d")?.drawImage(ev.target as HTMLVideoElement, longSide===videoWidth?(longSide-shortSide)/2:0, longSide===videoHeight?(longSide-shortSide/2):0 ,shortSide, shortSide, 0, 0, canvas.width, canvas.height)
                props.fromCamera(canvas.toDataURL("image/png"));
                ((ev.target as HTMLVideoElement).srcObject as MediaStream).getVideoTracks().forEach((tr)=>{
                    tr.stop()
                })
                setCameraState("idle")
            }
        }}/>}
        {props.picture == null && <a className={`profile_link ${props.linkClass ?? ""}`} href="https://www.flaticon.com/de/kostenlose-icons/phantom-der-oper" title="phantom der oper Icons">Phantom der oper Icons erstellt von Freepik - Flaticon</a>}

        {props.canEdit && <div className={`profile_edit_wrapper ${props.editWrapperClass ?? ""}`}>
            <input type="file" accept="image/*" ref={inputRef} style={{ display: "none" }} onChange={(ev) => {
                if (ev.target.files) {
                    const selected = ev.target.files[0]
                    props.onFileSelect(selected)
                }
            }} />
            <ShimmerButton className={props.selectButtonClass} onClick={() => {
                const curr = inputRef.current;
                if (isAny(curr)) {
                    const click = (curr as any).click;
                    if (typeof click == "function") {
                        (curr as any).click();
                    }

                }
            }}>Select File for Upload</ShimmerButton>
            <ShimmerButton className={props.sendButtonClass} onClick={props.setProfilePicture}>Set Profile Picture</ShimmerButton>
        </div>}

    </div>
}

export default ProfilePicture