
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './module/Login';
import Dashboard from './module/Dashboard';
import { Provider } from 'react-redux';
import store from './redux/store';
import CreateAdmin from './module/CreateAdmin';
import NewUserDetails from './module/NewUserDetails';
import ErrorModal from './module/ErrorModal';
import AdminActionModal from './module/AdminActionModal';

function App() {
  return (
    <div className="App">
      <Provider store={store} >
      <RouterProvider router={createBrowserRouter([
        {
          path:"/",
          Component: Dashboard
        },
        {
          path: "/Login",
          Component: Login
        },
        {
          path:"/CreateAdmin",
          Component: CreateAdmin
        },
        {
          path:"/NewUserDetails",
          Component: NewUserDetails
        }
      ], {})} />
      <AdminActionModal />
      <ErrorModal />
      </Provider>
    </div>
  );
}

export default App;
