import { isAny, isBoolean, isString, isUndefined } from "./IdentityHelpers";

export interface newAdminResponse {
    isNew: boolean,
    id: string,
}

export interface LoginResponse extends newAdminResponse {
    localAdminNew: boolean | null
}

export function isNewAdminResponse(obj: unknown): obj is newAdminResponse {
    if (!isAny(obj)) {
        return false;
    }
    if (!isBoolean(obj.isNew) || !isString(obj.id)) {
        return false;
    }
    return true;
}

export function isLoginResponse(obj: newAdminResponse): obj is LoginResponse {
    if (!isUndefined((obj as any).localAdminNew) && !isBoolean((obj as any).localAdminNew)) {
        return false;
    }
    return true
}