import React from "react"

export interface LabeledInputProps{
    label: string;
    wrapperClass?:string;
    labelClass?:string;
    inputClass?:string;
    type?:React.HTMLInputTypeAttribute;
}

const LabledInput = (props: LabeledInputProps & {value?: string|number;
    onChange:(value: string)=>void;})=><div className={`labledinput_wrapper ${props.wrapperClass??""}`}>
    <label className={`labledinput_label ${props.labelClass??""}`}>{props.label}</label>
    <input className={`labledinput_input ${props.inputClass??""}`} type={props.type} value={props.value} onChange={(ev)=>{
        props.onChange(props.type==="checkbox"?`${ev.target.checked}`: ev.target.value);
    }} checked={props.value==="true"} />
</div>

export default LabledInput