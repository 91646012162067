import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GroupAll } from "../../model/Group";

interface GroupSliceState{
    all?: GroupAll[]
}

const initialState: GroupSliceState = {};

const groupSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        setAll:(state, action: PayloadAction<GroupAll[]>)=>{
            state.all = action.payload;
        },
        clear:(state)=>{
            state.all = undefined;
        }
    }
})

export const {setAll, clear} = groupSlice.actions;

export default groupSlice.reducer;