import { PropsWithChildren, ReactNode } from "react"
import ShimmerButton from "./ShimmerButton"

interface ModalProps{
    show:boolean,
    backgroundClass?:string
    wrapperClass?:string
    headerClass?:string
    bodyClass?:string
    footerClass?:string
    header: ReactNode
    footer?:{
        class?:string
        acceptButton:{
            text?: string
            class?: string
            onclick: (ev:any)=>void
        },
        rejectButton:{
            text?:string
            class?:string,
            onClick: (ev: any)=>void
        }
    }
}

function Modal(props: PropsWithChildren<ModalProps>){

    return <div style={props.show?{}:{display:"none"}} className={`modal_background ${props.backgroundClass??""}`}>
        <div className={`modal_wrapper ${props.wrapperClass??""}`}>
            <div className={`modal_header ${props.headerClass??""}`}>
                {props.header}
            </div>
            <div className={`modal_body ${props.bodyClass??""}`}>
                {props.children}
            </div>
            {props.footer!==undefined&&<div className={`modal_footer ${props.footer.class??""}`}>
                <ShimmerButton onClick={props.footer.acceptButton.onclick} className={`modal_accept ${props.footer.acceptButton.class??""}`}>{props.footer.acceptButton.text??"Accept"}</ShimmerButton>
                <ShimmerButton onClick={props.footer.rejectButton.onClick} className={`modal_reject ${props.footer.rejectButton.class??""}`}>{props.footer.acceptButton.text??"Reject"}</ShimmerButton>
            </div>}
        </div>
    </div>

}

export default Modal;