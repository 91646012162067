import { useState } from "react";
import DisplayField from "../../components/DisplayField";
import LabledInput from "../../components/LabledInput";
import { ClientApp } from "../../model/ClientApp";
import { setAction } from "../../redux/reducers/adminAction";
import { useAppDispatch, useAppSelector } from "../../redux/store";

interface ClientAppViewProps {
    app: ClientApp
}

function ClientappView(props: ClientAppViewProps) {

    const [groupToLimit, setGroupToLimit] = useState("0")

    const dispatch = useAppDispatch()

    const groups = useAppSelector((state) => state.groupSlice.all)

    return <div>
        <DisplayField label={"Name"} field={props.app.name} />
        <DisplayField label="Client ID" field={props.app.id} />
        <DisplayField label="Client Secret" field={<div><button onClick={() => {
            navigator.clipboard.writeText(props.app.secret)
        }}>Copy to Clipboard</button><button onClick={()=>{
            dispatch(setAction({action:"refreshAppSecret", data: props.app.id}))
        }}>Refresh Secret</button></div>} />
        <h4>App limited to Groups:</h4>
        {
            props.app.limitedTo.length > 0 ? <ul>
                {props.app.limitedTo.map((g) => <li key={g.id}>{g.name} <button onClick={() => {
                    dispatch(setAction({ action: "removeGrouLimitapp", data: { groupId: g.id, appId: props.app.id } }))
                }}>Remove Group</button></li>)}
            </ul> : "App is available to all Groups"
        }
        <div>
            <select
                onChange={(ev) => {
                    setGroupToLimit(ev.target.selectedOptions[0].value)
                }}
            >
                {[<option key={"0"} value={"0"}>choose a Group...</option>, 
                ...(groups || [])
                .filter((g) => !props.app.limitedTo.find((ag) => ag.id === g.id))
                .map((g) => <option key={g.id} value={g.id}>{g.name}</option>)]}
            </select>
            <button onClick={() => {
                dispatch(setAction({ action: "addGroupLimitToApp", data: { appId: props.app.id, groupId: groupToLimit } }))
            }}>Limit App to Group</button>
        </div>
        {props.app.limitedTo.length > 0 && <LabledInput type="checkbox" value={`${props.app.limitedGroupsOnlyInToken}`} label="Limit Groups in Token" onChange={(_) => {
            dispatch(setAction({ action: "setAppGroupsTokenLimit", data: props.app.id }))
        }} />}
    </div>
}

export default ClientappView