import { useLocation, useNavigate } from "react-router"
import { setUserDetails } from "../api/User"
import Form from "../components/Form"
import { isAny } from "../model/IdentityHelpers"
import { useAppDispatch, useAppSelector } from "../redux/store"
import { clear, setCurrent } from "../redux/reducers/userSlice"

function NewUserDetails(){

    const current = useAppSelector((state)=>state.userSlice.current)

    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    return <div className="module login">
        <Form headerContent={"Fill in your Details"} bodyElements={[{
            name: "name", label:"Full Name"
        },
        {
            name: "displayName", label: "Display Name"
        }
        
        ]} submitAction={async(data)=>{
            if(isAny(data)&&current){
                dispatch(setCurrent({...current, ...data}))
                try{
                    await setUserDetails({...data, id: current.id, username: current.username})
                    if(searchParams.get("oidc")){
                        window.location.href = "/api/auth/openid/"+searchParams.get("oidc")+"?"+searchParams.toString()
                    }else{
                        navigate("/")
                    }
                }catch(e){
                    dispatch(clear())
                } 
            }
        }} cancelAction={console.log} />
        </div>
}

export default NewUserDetails