import { Group, isGroup } from "./Group";
import { isAny, isArrayOf, isString } from "./IdentityHelpers";

export interface Domain{
    domain: string,
    groups: Group[]
}

export function isDomain(obj: unknown): obj is Domain{
    if(!isAny(obj)){
        return false;
    }
    if(!isString(obj.domain)){
        return false;
    }
    if(!isArrayOf(obj.groups, isGroup)){
        return false;
    }
    return true;
}