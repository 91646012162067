import { isAny, isArrayOf, isString, isUndefined } from "./IdentityHelpers";

export interface Group {
    id: string;
    name: string;
}

export interface GroupAll extends Group {
    apps: string[];
    domains: string[];
    users: UserFragmentGroup[]
}

export interface UserFragmentGroup {
    id: string,
    displayName?: string
}

export function isGroup(obj: unknown): obj is Group {
    if (!obj) {
        return false;
    }
    if (!isString((obj as any).id) || !isString((obj as any).name)) {
        return false;
    }
    return true;
}

export function isUserFragmentGroup(obj: unknown): obj is UserFragmentGroup {
    if (!isAny(obj)) {
        return false;
    }
    if (!isString(obj.id)) {
        return false;
    }
    if (!isUndefined(obj.displayName) && !isString(obj.displayName)) {
        return false;
    }

    return true;
}

export function isGroupAll(obj: Group): obj is GroupAll {
    if (!isArrayOf((obj as any).apps, isString)) {
        return false;
    }
    if (!isArrayOf((obj as any).domains, isString)) {
        return false;
    }
    if (!isArrayOf((obj as any).users, isUserFragmentGroup)) {
        return false;
    }

    return true;
}