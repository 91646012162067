import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Setting } from "../../model/Setting";

interface SettingsSliceState{
    all?:Setting[]
}

const initialState: SettingsSliceState = {}

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setAll:(state, action: PayloadAction<Setting[]>)=>{
            state.all = action.payload;
        },
        clear:(state)=>{
            state.all = undefined;
        }
    }
})

export const {setAll, clear} = settingsSlice.actions;

export default settingsSlice.reducer;