import { useNavigate } from "react-router";
import { createAdmin } from "../api/Auth";
import Form from "../components/Form";
import { useAppDispatch } from "../redux/store";
import { setCurrent } from "../redux/reducers/userSlice";

function CreateAdmin() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    return <div className="module login">
        <Form headerContent={"Create New Admin"} bodyElements={[{
            name: "email", label: "email address", type: "email"
        },
        {
            name: "password", label: "email password", type: "password"
        },
        {
            name: "adminPassword", label: "admin password", type: "password"
        }
        ]} submitAction={async (data) => {
            try {
                const newAdmin = await createAdmin(data)
                if (newAdmin.isNew) {
                    dispatch(setCurrent({
                        username: (data as any).email!, id: newAdmin.id,
                        picture: null,
                        parent: null,
                        adminId: null
                    }))
                    navigate("/NewUserDetails")
                } else {
                    navigate("/")
                }
            } catch (e) {
                console.error(e)
                navigate("/")
            }
        }} cancelAction={() => {
            navigate("/")
        }} />
    </div>
}

export default CreateAdmin;