import { useState } from "react";
import DisplayField from "../../components/DisplayField";
import { Domain } from "../../model/Domain";
import { setAction } from "../../redux/reducers/adminAction";
import { useAppDispatch, useAppSelector } from "../../redux/store";

interface DomainViewProps {
    domain: Domain
}

function DomainView(props: DomainViewProps) {

    const [groupToAdd, setGroupToAdd] = useState("0")

    const groups = useAppSelector((state) => state.groupSlice.all)

    const dispatch = useAppDispatch()

    return <div>
        <DisplayField label="Domain" field={props.domain.domain} />
        <h4>Domain Groups</h4>
        {props.domain.groups.length > 0 ? <ul>
            {props.domain.groups.map((g) => <li key={g.id}>{g.name} <button onClick={() => {
                dispatch(setAction({ action: "domainRemoveGroup", data: { domain: props.domain.domain, groupId: g.id } }))
            }}>Remove</button></li>)}
        </ul> : "No Groups"}
        <div>
            <select onChange={(ev) => {
                setGroupToAdd(ev.target.selectedOptions[0].value)
            }}>
                <option value={"0"}>Select a Group...</option>
                {groups !== undefined && groups.filter((g) => !props.domain.groups.find((dg) => dg.id === g.id)).map((g) => <option key={g.id} value={g.id}>{g.name}</option>)}
            </select>
            <button onClick={() => {
                if (groupToAdd !== "0")
                    dispatch(setAction({ action: "domainAddGroup", data: { domain: props.domain.domain, groupId: groupToAdd } }))
            }}>Add Group</button>
        </div>
        <button onClick={() => {
            dispatch(setAction({ action: "deleteDomain", data: props.domain.domain }))
        }} >Delete</button>
    </div>
}

export default DomainView