import { useState } from "react";
import DisplayField from "../../components/DisplayField";
import { GroupAll } from "../../model/Group";
import { setAction } from "../../redux/reducers/adminAction";
import { useAppDispatch, useAppSelector } from "../../redux/store";

interface GroupViewProps{
    group: GroupAll,
    adminActions?:boolean
}

function GroupView(props: GroupViewProps){

    const [addUser, setAddUser] = useState("0");

    const dispatch = useAppDispatch()

    const {all} = useAppSelector((state)=>state.userSlice)

    return <div>
        <DisplayField label="Name" field={props.group.name}/>
        <div>
            <h4>Users</h4>
            <ul>
                {props.group.users.map((u)=><li key={u.id} >{u.displayName}{props.adminActions&&<button onClick={()=>{
                    dispatch(setAction({action:"removeUserFromGroup", data:{userId: u.id, groupId: props.group.id}}))
                }}>Remove</button>}</li>)}
            </ul>
        </div>
        {props.adminActions&&<div><select
        onChange={(e)=>{
            setAddUser(e.target.selectedOptions[0].value)
        }}
        onClick={()=>{
            if(!all){
                dispatch(setAction({action:"getUsers"}))
            }
        }}>{[<option key="0" value={"0"}>Please choose...</option>,(all||[]).filter((u)=>!props.group.domains.find((d)=>u.username.endsWith(d))&& !props.group.users.find((gu)=>gu.id===u.id)).map((u)=><option value={u.id} key={u.id}>{u.name??u.username}</option>)]}</select><button onClick={()=>{
            if(addUser!=="0"){
                dispatch(setAction({action:"addUserToGroup", data:{userId: addUser, groupId: props.group.id}}))
            }
        }}>Add User</button></div>}
        <div>
            <h4>
                Domains
            </h4>
            <ul>
                {props.group.domains.map((d)=><li key={d}>{d}</li>)}
            </ul>
        </div>
        <div>
            <h4>
                Applications
            </h4>
            <ul>
                {props.group.apps.map((a)=><li key={a}>{a}</li>)}
            </ul>
        </div>
        
    </div>
}

export default GroupView;