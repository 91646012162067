import { useCallback, useEffect } from "react";
import { assignAdmin } from "../api/Auth";
import { getAllUsers } from "../api/User";
import LabledInput from "../components/LabledInput";
import Modal from "../components/Modal";
import { clear, clearActionData, setPassword, unsetPass } from "../redux/reducers/adminAction";
import * as userDispatch from "../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { addUserToGroup, createGroup, getGroups, removeUserFromGroup } from "../api/Groups";
import * as groupDispatch from "../redux/reducers/groupSlice";
import { addGroupToAppLimit, getClientApps, newClientapp, refreshClientAppSecret, removeGroupFromAppLimit, toggleClientAppGroupLimitPropagation } from "../api/ClientApp";
import * as clientAppDispatch from "../redux/reducers/clientAppSlice";
import * as domainDispatch from "../redux/reducers/domainSlice";
import { deleteDomain, domainAddGroup, domainRemoveGroup, getDomains, newDomain } from "../api/Domain";
import { isAny, isString } from "../model/IdentityHelpers";

function AdminActionModal(){
    
    const action = useAppSelector((state)=>state.adminAction)

    const dispatch = useAppDispatch();
    
    const performAdminAction = useCallback(async(_: any)=>{
        switch(action.adminAction){
            case "assignAdmin":
                await assignAdmin({password: action.password}, action.data)
                break;
            case "getUsers":
                
                break;
            case "addUserToGroup":
                await addUserToGroup({password: action.password, ...action.data})
                break;
            case "removeUserFromGroup":
                await removeUserFromGroup({password: action.password, ...action.data})
                break;
            case "newGroup":
                await createGroup({password: action.password, ...action.data})
                break;
            case "addGroupLimitToApp":
                await addGroupToAppLimit({password: action.password, ...action.data})
                break;
            case "removeGrouLimitapp":
                await removeGroupFromAppLimit({password: action.password, ...action.data})
                break;
            case "getClientApps":
                
                break;
            case "setAppGroupsTokenLimit":
                await toggleClientAppGroupLimitPropagation({password: action.password, appId: action.data})
                break;
            case "newClientApp":
                await newClientapp({password: action.password, ...action.data})
                break;
            case "refreshAppSecret":
                await refreshClientAppSecret({password: action.password, appId: action.data})
                break;
            case "getDomains":
                break;
            case "newDomain":
                await newDomain({password:action.password, ...action.data})
                break;
            case "deleteDomain":
                await deleteDomain({password: action.password, domain: action.data})
                break;
            case "domainAddGroup":
                await domainAddGroup({password: action.password, ...action.data})
                break;
            case "domainRemoveGroup":
                await domainRemoveGroup({password: action.password, ...action.data})
                break;
            default:
                console.log(action.data)
        }
        dispatch(clearActionData())
        dispatch(setPassword(action.password!))
        dispatch(userDispatch.setAll(await getAllUsers({password: action.password})))
        dispatch(groupDispatch.setAll(await getGroups()))
        dispatch(clientAppDispatch.setAll(await getClientApps({password: action.password})))
        dispatch(domainDispatch.setAll(await getDomains({password: action.password})))
    }, [action, dispatch])

    useEffect(()=>{
        const inter = setInterval(()=>{
            if(action.lastEntered&&Date.now()-action.lastEntered>=300000){
                dispatch(unsetPass())
            }
            console.log(300000-(Date.now()-(action.lastEntered??0)))
        }, 10000)
        if(action.adminAction&&action.lastEntered&&Date.now()-action.lastEntered>3000&&Date.now()-action.lastEntered<300000){
            performAdminAction(null).catch((e: unknown)=>{
                if(isAny(e)&&isString(e.message)&&!isNaN(parseInt(e.message.split(":")[0].trim()))&&parseInt(e.message.split(":")[0].trim())===401){
                    dispatch(clear())
                }else{
                    dispatch(clearActionData())
                }
            })
        } 
        return ()=>clearInterval(inter);
    }, [action, performAdminAction, dispatch])


    

    const shownModal = action.adminAction!==undefined&&(!action.lastEntered||!(Date.now()-action.lastEntered>3000&&Date.now()-action.lastEntered<300000))

    return <Modal show={shownModal} header={"Admin Input required"} footer={{acceptButton:{
        onclick: ()=>{
           performAdminAction(null).catch((e: unknown)=>{
                if(isAny(e)&&isString(e.message)&&!isNaN(parseInt(e.message.split(":")[0].trim()))&&parseInt(e.message.split(":")[0].trim())===401){
                    dispatch(clear())
                }else{
                    dispatch(clearActionData())
                }
            })
        }
    }, rejectButton:{
        onClick:(_)=>{
            dispatch(clearActionData())
        }
    }}}>
        <LabledInput label={"Password"} type="password" onChange={(value)=>{
            dispatch(setPassword(value))
        }} value={action.password??""} />
    </Modal>
}

export default AdminActionModal;