export function isArrayOf<T>(obj: unknown, itemVerifier: (o: unknown) => o is T): obj is T[] {
    return Array.isArray(obj) && !(obj as unknown[]).find((o) => !itemVerifier(o))
}

export function isString(o: unknown): o is string {
    return typeof o == "string" && o !== "null" && o !== "undefined";
}

export function isNumber(o: unknown): o is number {
    return typeof o == "number" && !isNaN(o as number);
}

export function isBoolean(o: unknown): o is boolean {
    return typeof o == "boolean";
}

export function isUndefined(o: unknown): o is null | undefined {
    return !o && o !== false && o !== 0;
}

export function isAny(o: unknown): o is any {
    return !isUndefined(o);
}