import { PayloadAction, createSlice } from "@reduxjs/toolkit";


type AdminAction = "assignAdmin"
|"getUsers"
|"removeUserFromGroup"
|"addUserToGroup"
|"newGroup"
|"setAppGroupsTokenLimit"
|"removeGrouLimitapp"
|"addGroupLimitToApp"
|"getClientApps"
|"newClientApp"
|"refreshAppSecret"
|"domainRemoveGroup"
|"domainAddGroup"
|"getDomains"
|"newDomain"
|"deleteDomain"

interface AdminActionState{
    password?:string,
    lastEntered?:number
    data?:any,
    adminAction?:AdminAction
}

const initialState: AdminActionState = {

}

const adminActionSlice = createSlice({
    name: "adminaction",
    initialState,
    reducers: {
        setAction(state, action: PayloadAction<{data?: any, action: AdminAction}>){
            state.adminAction = action.payload.action
            state.data = action.payload.data
        },
        clearActionData(state){
            state.adminAction = undefined;
            state.data = undefined;
        },
        setPassword(state, action: PayloadAction<string>){
            state.password = action.payload
            state.lastEntered = Date.now()
        },
        setActionData(state, action: PayloadAction<any>){
            state.data = action.payload
        },
        clear(state){
            state.adminAction = undefined
            state.data = undefined
            state.lastEntered = undefined
            state.password = undefined
        },
        unsetPass(state){
            state.password=undefined
            state.lastEntered=undefined
        }
    }
})

export const {setAction, setPassword, clearActionData, setActionData, clear, unsetPass} = adminActionSlice.actions

export default adminActionSlice.reducer