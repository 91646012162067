import { isLoginResponse, isNewAdminResponse } from "../model/Auth";
import { isString } from "../model/IdentityHelpers";
import { api_url, jsonRequest, routeconf } from "./Const";
import { hasAdminPassword, isAuthRequest, isCreateAdminRequest } from "./DTO";

async function login(request: unknown){
    if(!isAuthRequest(request)){
        throw new TypeError(`${JSON.stringify(request)} is not AuthRequest`)
    }
    
    const loginData = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Auth.Base}${routeconf.Auth.Login}`, request)

    if(!isNewAdminResponse(loginData)||!isLoginResponse(loginData)){
        throw new TypeError(`${JSON.stringify(loginData)} is not LoginResponse`)
    }

    return loginData;
    
    // fetch(`${api_url}${routeconf.Base}${routeconf.Auth.Base}${routeconf.Auth.Login}`, {method:"POST", body:JSON.stringify(request), headers:{"Content-Type":"application/json"}})
}

async function createAdmin(request: unknown){
    if(!isCreateAdminRequest(request)){
        throw new TypeError(`${JSON.stringify(request)} is not CreateAdminRequest`)
    }

    const createAdminData = await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Auth.Base}${routeconf.Auth.CreateAdmin}`, request)

    if(!isNewAdminResponse(createAdminData)){
        throw new TypeError(`${JSON.stringify(createAdminData)} is not NewAdminResponse`)
    }

    return createAdminData;

}

async function assignAdmin(request: unknown, userId: string){
    if(!hasAdminPassword(request)){
        throw new TypeError(`${JSON.stringify(request)} has no admin password`)
    }
    if(!isString(userId)){
        throw new TypeError(`${userId} must be string`)
    }
    await jsonRequest("POST", `${api_url}${routeconf.Base}${routeconf.Auth.Base}/${userId}`, request, true)

}

async function checkNeedsAdmin(){
    return (await (await fetch(`${api_url}${routeconf.Base}${routeconf.Auth.Base}${routeconf.Auth.Base}`)).json()).adminNeeded === true
}

export {
    login,
    createAdmin,
    checkNeedsAdmin,
    assignAdmin
}