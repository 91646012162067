import { ReactNode, useState } from "react";
import LabledInput, { LabeledInputProps } from "./LabledInput";
import ShimmerButton from "./ShimmerButton";


export interface FormProps {
    wrapperClass?: string;
    headerWrapperClass?: string;
    headerContent: ReactNode;
    bodyWrapperClass?: string;
    bodyElements: (LabeledInputProps & { name: string })[]
    buttonWrapperClass?: string;
    buttonClass?: string;
    buttonAcceptClass?: string;
    buttonRejectClass?: string;
    buttonAcceptContent?: ReactNode;
    buttonRejectContent?: ReactNode;
    submitAction: (obj: unknown) => void | Promise<void>
    cancelAction: () => void | Promise<void>
}

const Form = (props: FormProps) => {

    const [formState, setFormState] = useState<any>({})

    return <div className={`form_wrapper ${props.wrapperClass ?? ""}`}>
        <div className={`form_header ${props.headerWrapperClass ?? ""}`}>
            {props.headerContent}
        </div>
        <div className={`form_body ${props.bodyWrapperClass ?? ""}`}>
            {props.bodyElements.map((props) => <LabledInput key={props.name} {...props} value={formState[props.name]} onChange={(value) => {
                const newState = formState;
                newState[props.name] = value;
                setFormState(newState);
            }} />)}
        </div>

        <div className={`form_buttonwrapper ${props.buttonWrapperClass ?? ""}`}>
            <ShimmerButton className={`form_accept ${props.buttonClass ?? ""} ${props.buttonAcceptClass ?? ""}`} onClick={() => {
                const submitAction = props.submitAction(formState);
                if (submitAction) {
                    submitAction.then(() => {
                        setFormState({})
                    })
                } else {
                    setFormState({})
                }
            }}>
                {props.buttonAcceptContent ?? "Accept"}
            </ShimmerButton>
            <ShimmerButton onClick={props.cancelAction} className={`form_reject ${props.buttonClass ?? ""} ${props.buttonRejectClass ?? ""}`}>
                {props.buttonRejectContent ?? "Reject"}
            </ShimmerButton>
        </div>
    </div>
}

export default Form;