import { Group, isGroup } from "./Group";
import { isAny, isArrayOf, isString, isUndefined } from "./IdentityHelpers";

export interface RemoteUser {
    id: string,
    name?: string,
    displayName?: string,
    username: string,
    picture: string | null,
    parent: RemoteUser | null;
    children?: RemoteUser[];
    adminId: string | null;
    groups?: Group[];
}

export function isRemoteUser(obj: unknown): obj is RemoteUser {
    if (!isAny(obj)) {
        return false
    }
    if (!isString(obj.id) || !isString(obj.username)) {
        return false;
    }
    if (!isUndefined(obj.name) && !isString(obj.name)) {
        return false;
    }
    if (!isUndefined(obj.displayName) && !isString(obj.displayName)) {
        return false;
    }
    if (!isUndefined(obj.picture) && !isString(obj.picture)) {
        return false;
    }
    if (!isUndefined(obj.adminId) && !isString(obj.adminId)) {
        return false;
    }
    if (!isUndefined(obj.parent) && !isRemoteUser(obj.parent)) {
        return false;
    }
    if (!isUndefined(obj.children) && !isArrayOf(obj.children, isRemoteUser)) {
        return false;
    }
    if (!isUndefined(obj.groups) && !isArrayOf(obj.groups, isGroup)) {
        return false;
    }
    return true
}