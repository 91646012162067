import { Group, isGroup } from "./Group";
import { isAny, isArrayOf, isBoolean, isString } from "./IdentityHelpers";

export interface ClientApp{
    id: string;
    secret: string;
    name: string;
    redirectUrl: string;
    limitedGroupsOnlyInToken: boolean;
    limitedTo: Group[]
}

export function isClientApp(obj: unknown): obj is ClientApp{
    if(!isAny(obj)){
        return false;
    }
    if(!isString(obj.id)||!isString(obj.secret)||!isString(obj.name)||!isString(obj.redirectUrl)||!isBoolean(obj.limitedGroupsOnlyInToken)){
        return false;
    }
    if(!isArrayOf(obj.limitedTo, isGroup)){
        return false;
    }

    return true;
}