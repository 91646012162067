import { useLocation, useNavigate } from "react-router"
import { login } from "../api/Auth"
import Form from "../components/Form"
import { useAppDispatch } from "../redux/store"
import { setCurrent } from "../redux/reducers/userSlice"


interface LoginProps {

}

const Login = (props: LoginProps) => {

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    return <div className="module login">
        <Form headerContent={"Login"} buttonAcceptClass="login_btn_accept" buttonRejectClass="login_btn_reject" buttonAcceptContent="Login" buttonRejectContent="Cancel" bodyElements={[{
            label: "email address", name: "email"
        }, {
            label: "email password", name: "password", type: "password"
        }]} submitAction={async (data) => {
            const loginResponse = await login(data)
            if (loginResponse.isNew) {
                dispatch(setCurrent({
                    username: (data as any).email!, id: loginResponse.id,
                    picture: null,
                    parent: null,
                    adminId: null
                }))
                if (searchParams.get("oidc")) {
                    navigate("/NewUserDetails?" + searchParams.toString())
                } else {
                    navigate("/NewUserDetails")
                }
                // + searchParams.get("oidc") === "auth" ? "?" + searchParams.toString() : "")
            } else if (searchParams.get("oidc")) {
                window.location.href = "/api/auth/openid/" + searchParams.get("oidc") + "?" + searchParams.toString()
            } else {
                navigate("/")
            }

        }} cancelAction={() => {
            navigate("/")
        }} />

    </div>

}

export default Login